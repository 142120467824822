// -- libs
import axios from "axios";

// -- Axios Backend Connection Setup
const frontEndURL = new URL(window.location.href);
const { protocol, hostname } = frontEndURL;

// BJFTODO: how to handle ezgmp BE?
const backendHost = {
  local: `${protocol}//${hostname}:9000/v1`,
  demo: "https://fargate-lb.demo.ezgmp.app/v1/",
  development: 'https://fargate-lb.develop.omniblocks.app/v1/',
  staging: 'https://fargate-lb.staging.omniblocks.app/v1/',
  production: "https://fargate-lb.production.omniblocks.app/v1/",
};

// Fallback - local development
let backendEnv = "local";
if (hostname.includes("staging.") || hostname.includes("ds-stage.")) {
  backendEnv = "staging";
} else if (hostname.includes("develop.")) {
  backendEnv = "development";
} else if (hostname.includes("demo.")) {
  backendEnv = "demo";
} else if (hostname.includes("info.") || hostname.includes("main.") || hostname.includes("production.")) {
  backendEnv = "production";
}
const envName = backendEnv.charAt(0).toUpperCase() + backendEnv.slice(1);

const timeout = 180000; // 2m = 120,000 ms
const baseURL = backendHost[backendEnv];
const api = axios.create({
  baseURL,
  timeout,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_OMNIBLOCKS_API_TOKEN}`,
  },
});

export default api;
export { baseURL, envName };
//

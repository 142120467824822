// -- libs
import { Tooltip, Row, Col, Layout } from 'antd';
import { envName } from '@utils/axios';
import pkg from '../../package.json';

// -- assets
import '@assets/css/OurFooter.css';

// -- setup
const { Footer } = Layout;
const thisYear = new Date().getFullYear();

const versionDisplay = (
  <Tooltip
    title={
      <>
        {`FrontEnd: ${pkg.version}`}
      </>
    }
  >
    {pkg.version}
  </Tooltip>
);

const OurFooter = () => {
  return <Footer className="footer">
    <Row>
      <Col span={8} style={{textAlign:'left'}}>{envName}</Col>
      <Col span={8}>AMNI ©2020 - {thisYear}</Col>
      <Col span={8} style={{textAlign:'right'}}>{versionDisplay}</Col>
    </Row>
  </Footer>;
}

export default OurFooter;
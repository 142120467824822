import { Row, Col, Typography, Space, Divider, Button } from "antd";
import ImageCarousel from "@components/ImageCarousel";
import { useMediaQuery } from "hooks/useMediaQuery";
import "assets/css/info.css";
function Productinfo({ product }) {
	const isDeskTop = useMediaQuery("(min-width:992px)");
	const { filelist, product_name, batch_no, expiry_date, product_description, product_info } =
		product;

	return (
		<Row gutter={{ xs: 0, lg: 64 }}>
			<Col xs={24} lg={12}>
				<ImageCarousel fileList={filelist} />
			</Col>
			<Col
				xs={24}
				lg={12}
				style={{
					marginTop: isDeskTop ? "" : "60px",
				}}>
				<Space align="start">
					<Typography.Title level={1} className="extra-bold">
						{product_name}
					</Typography.Title>
				</Space>

				<div className="flex">
					<span>{`Batch ${batch_no}`}</span>
					<span>{`Exp ${expiry_date}`}</span>
				</div>
				<Divider />
				<Typography.Text
					style={{
						fontSize: "1.0rem",
					}}>
					{product_description}
				</Typography.Text>
				<Divider />
				<div className="grid-column">
					{product_info &&
						product_info.length > 0 &&
						product_info.map(item => {
							const { id, info, details } = item;
							return (
								<div size="large" key={id} className="full grid-small">
									<span
										className="bold capitalize"
										style={{
											fontSize: "0.92rem",
										}}>
										{info}
									</span>
									{info === "Website" ? (
										<span
											className="normal"
											style={{
												fontSize: "0.9rem",
											}}>
											<a href={details}>{details}</a>
										</span>
									) : (
										<span
											className="normal capitalize"
											style={{
												fontSize: "0.9rem",
											}}>
											{details}
										</span>
									)}
								</div>
							);
						})}
				</div>
				<Divider />
				<Space>
					<Button href="https://www.amni.ai/" target="rbt" type="primary" size="large">
						Website
					</Button>
					<Button  href="https://www.amni.ai/contact-us"  target="rbt" type="outline" size="large">
						Contact us
					</Button>
				</Space>
				<Divider />
			</Col>
		</Row>
	);
}

export default Productinfo;
